<template>
    <modal ref="modalAgregarEquipoModelo" titulo="Añadir equipo modelo" adicional="Añadir" @adicional="agregarEquipoMOdelo">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-9 my-2">
                <p class="input-label-top">Equipo modelo</p>
                <ValidationObserver ref="validator">
                    <ValidationProvider v-slot="{ errors }" name="equipo" rules="required">
                        <el-select v-model="idEquipo" placeholder="Seleccionar equipo modelo" size="small" class="w-100">
                            <el-option v-for="item in select_opportunity_equipos" :key="item.id" :label="item.nombre" :disabled="equipos.some( el => el.id_equipo == item.id )" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </ValidationObserver>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data(){
        return {
            idEquipo: '',
        }
    },
    computed: {
        ...mapGetters({
            select_opportunity_equipos: 'selects/selects/select_opportunity_equipos',
            equipos: 'compras/ordenes_compra/equipos',
        }),
    },
    methods: {
        ...mapActions({
            Action_get_opportunity_equipos: 'oportunidades/oportunidades/Action_get_opportunity_equipos',
            Action_get_select_opportunity_equipos: 'selects/selects/Action_get_select_opportunity_equipos',
            Action_add_opportunity_equipo: 'oportunidades/oportunidades/Action_add_opportunity_equipo',
            obtenerEquipos:'compras/ordenes_compra/Action_get_equipos',
        }),
        async toggle(){
            await this.Action_get_select_opportunity_equipos()
            this.idEquipo = ''
            this.$refs.validator.reset()
            this.$refs.modalAgregarEquipoModelo.toggle()
        },
        async agregarEquipoMOdelo(){
            const valid = await this.$refs.validator.validate();
            if (valid) {
                const params = {
                    id: this.$route.params.id,
                    payload: { id_equipo_modelo: this.idEquipo }
                }
                await this.Action_add_opportunity_equipo(params)
                this.$refs.modalAgregarEquipoModelo.toggle()
                await this.obtenerEquipos({id_oportunidad: this.$route.params.id});
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
